<template>
    <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('globalTrans.details')}}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loader">
                <b-row>
                    <b-col md="12" class="table-responsive">
                        <b-table-simple striped bordered small>
                            <b-tr>
                                <b-th>{{$t('globalTrans.organization')}} </b-th>
                                <b-td>{{ (localLan === 'bn') ? vRequest.org_name_bn : vRequest.org_name  }} </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('tour_management.visit_request_id')}}</b-th>
                                <b-td>{{ vRequest.request_id }}</b-td>
                            </b-tr>
                             <b-tr>
                                <b-th>{{ $t('pp_visit_request.request_type')}}</b-th>
                                <b-td>{{  (localLan === 'bn') ? vRequest.request_type_bn : vRequest.request_type }}</b-td>
                            </b-tr>
                             <b-tr>
                                <b-th>{{ $t('pp_visit_request.institute_name')}}</b-th>
                                <b-td>{{ vRequest.institute_name ? vRequest.institute_name : 'N/A' }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('pp_visit_request.number_of_visitor')}}</b-th>
                                <b-td>{{ vRequest.no_of_visitor ? vRequest.no_of_visitor : 1 }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('pp_visit_request.contact_person_name')}}</b-th>
                                <b-td>{{ vRequest.contact_person_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('pp_visit_request.nid_no')}}</b-th>
                                <b-td>{{ vRequest.nid_no }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('pp_visit_request.mobile_no')}}</b-th>
                                <b-td>{{ vRequest.mobile_no }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('pp_visit_request.email')}}</b-th>
                                <b-td>{{ vRequest.email }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('tour_management.requested_visit_date')}}</b-th>
                                <b-td>{{ vRequest.visit_date }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('pp_visit_request.time_slot')}}</b-th>
                                <b-td>{{ vRequest.time_slot }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('tour_management.tour_operator_name')}}</b-th>
                                <b-td>{{ vRequest.tour_operator_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('tour_management.tour_operator_designation')}}</b-th>
                                <b-td>{{ vRequest.tour_operator_designation }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('tour_management.tour_operator_mobile_no')}}</b-th>
                                <b-td>{{ vRequest.tour_operator_mobil_no }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('pp_visit_request.remarks')}}</b-th>
                                <b-td>{{ vRequest.remarks }}</b-td>
                            </b-tr>
                        </b-table-simple>
                             <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col text-right">
                                  <b-button v-if="vRequest.rview_dt_set_status == 1 && vRequest.status !== 3 && vRequest.status !== 4 && vRequest.status !== 5" class="mr-1" title="Review" v-b-modal.modal-review variant="success" @click="reviewData()">{{ $t('tour_management.review') }}</b-button>
                                    &nbsp;
                                  <b-button variant="danger" class="mr-1" @click.prevent="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                            </div>
                    </b-col>
                </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-review" size="lg" :title="$t('tour_management.review')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Review :id="itemId" :requestid="requestId" :key="itemId"/>
    </b-modal>

    <b-modal id="modal-reject" size="lg" :title="$t('tour_management.reject')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Reject :id="itemId" :requestid="requestId" :key="itemId"/>
    </b-modal>
    <b-modal id="modal-approve" size="lg" :title="$t('tour_management.approve')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Approve :id="itemId" :requestid="requestId" :orgid="orgId" :key="itemId"/>
    </b-modal>
    </b-container>
</template>

<script>
import Review from './Review'
import Reject from './Reject'
import Approve from './Approve'
// import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
// import { insectManageDetailsApi } from '../../api/routes'
// import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  // mixins: [ModalBaseMasterList],
  components: {
        Review, Reject, Approve
    },
  props: ['item'],
  data () {
    return {
      vRequest: {},
      loader: false,
      localLan: this.$i18n.locale,
      itemId: 0,
      requestId: 0,
      orgId: 0
    }
  },
  created () {
    this.vRequest = this.item
  },
  methods: {
    reviewData () {
      this.itemId = this.item.id
      this.requestId = this.item.request_id
    },
    rejectData () {
      this.itemId = this.item.id
      this.requestId = this.item.request_id
    },
    approveData () {
     this.itemId = this.item.id
      this.requestId = this.item.request_id
      this.orgId = this.item.org_id
    }
  }
}
</script>
<style scoped>
.padding_left {
 padding-left: 15px
}
</style>
